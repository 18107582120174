import {
  httpAppcorp,
  httpSchools,
  httpAppmembers,
  httpMetaDatas,
  httpChannelList,
  httpAllBranchMember,
} from "@/api/common";

export const httpCascaderMembers = async (id, level) => {
  let options = [];
  let path = httpAppcorp;
  try {
    if (level === 1) {
      path = httpSchools;
    } else if (level === 2) {
      path = httpAppmembers;
    }
    const res = await path({ id });
    const list = res?.data;
    if (list) {
      options = list.map((entry) => ({
        value: level === 2 ? entry.id : entry.deptId,
        label: entry.name,
        level: level + 1,
      }));
    }
  } catch (error) {
    console.log(error);
  }
  return options;
};

// 获取性别、年级、学员类型
export const httpDicts = async (name, region = "customer") => {
  let options = {};
  try {
    const res = await httpMetaDatas({ region, name });
    if (res?.success) {
      const opt = res.data.optionValueV2?.map((el) => {
        return {
          ...el,
          label: el.name,
          value: el.id,
          text: el.name,
          key: el.id,
        };
      });
      options = {
        ...res.data,
        pickerOptions: opt,
      };
    }
  } catch (error) {
    console.log(error);
  }
  return options;
};

// 获取性别、年级、学员类型
export const httpChannels = async () => {
  let options = {};
  try {
    const res = await httpChannelList();
    if (res?.success) {
      const opt = res.data?.map((el) => {
        return {
          ...el,
          label: el.title,
          value: el.id,
          text: el.title,
          key: el.id,
          children: el?.channelVOs
            ? el.channelVOs.map((it) => {
                return {
                  ...it,
                  label: it.title,
                  value: it.id,
                };
              })
            : [],
        };
      });
      options = {
        pickerOptions: opt,
      };
    }
  } catch (error) {
    console.log(error);
  }
  return options;
};

const unique3 = (arr) => {
  const newArray = arr.filter((item, index) => {
    return arr.findIndex((f) => f.id === item.id) === index;
  });
  return newArray;
};

const getPhoneOrderName = (order) => {
  return { 1: "一接", 2: "二接", 3: "三接", 9: "兜底" }[order] || "";
};

/**
 * 查询全量分公司、校区、人员
 * @returns filtered 移除没有人员的分公司 校区，source 全量数据
 */
export const getAllBranchMember = async () => {
  let options = {};
  try {
    const res = await httpAllBranchMember();
    const filtered = (res.data || [])
      .map((m) => {
        return {
          ...m,
          label: m.name,
          title: m.name,
          value: m.deptId,
          allotCorp: m.name,
          allotCorpId: m.deptId,
          children: (m.children || [])
            .filter((f) => (f.appMembers || []).length)
            .map((campus) => {
              return {
                ...campus,
                label: campus.name,
                title: campus.name,
                value: campus.deptId,
                allotCampus: campus.name,
                allotCampusId: campus.deptId,
                children: unique3(campus.appMembers || []).map((member) => {
                  const callPhone = `${member.counselorPhone || ""} ${
                    member.landlinePhone || ""
                  }`;
                  return {
                    ...member,
                    label: `${member.name}  ${getPhoneOrderName(
                      member.phoneOrder
                    )}  ${callPhone}`,
                    title: member.name,
                    value: member.id,
                    allotName: member.name,
                    allotId: member.id,
                    callPhone,
                  };
                }),
              };
            }),
        };
      })
      .filter((f) => (f.children || []).length);

    const source = (res.data || []).map((m) => {
      return {
        ...m,
        label: m.name,
        value: m.deptId,
        children: (m.children || []).map((campus) => {
          return {
            ...campus,
            label: campus.name,
            value: campus.deptId,
            children: undefined,
          };
        }),
      };
    });
    options = {
      pickerOptions: {
        filtered,
        source,
      },
    };
  } catch (error) {
    console.log(error);
  }
  return options;
};
