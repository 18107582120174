import { TabBar } from "antd-mobile";
// import kehu from "@/asstes/kehu.png";
// import kehucol from "@/asstes/kehucol.png";
import wode from "@/asstes/wode.png";
import wodecol from "@/asstes/wodecol.png";
// import xuesheng from "@/asstes/xuesheng.png";
// import xueshengcol from "@/asstes/xueshengcol.png";
// import xiansuo from "@/asstes/xiansuo.png";
// import xiansuocol from "@/asstes/xiansuocol.png";
import { useHistory } from "react-router-dom";
import home from "@/asstes/home.png";
import homecol from "@/asstes/homecol.png";
import styles from "./index.module.scss";

const TabBarCom = ({ pathname }) => {
  const history = useHistory();
  const tabs = [
    // {
    //   path: "/cluelist",
    //   title: "线索",
    //   defaulticon: xiansuo,
    //   secectedIcon: xiansuocol,
    // },
    // {
    //   path: "/",
    //   title: "客户",
    //   defaulticon: kehu,
    //   secectedIcon: kehucol,
    // },
    // {
    //   path: "/student",
    //   title: "学员管理",
    //   defaulticon: xuesheng,
    //   secectedIcon: xueshengcol,
    // },
    {
      path: "/",
      title: "首页",
      defaulticon: home,
      secectedIcon: homecol,
    },
    {
      path: "/mine",
      title: "我的",
      defaulticon: wode,
      secectedIcon: wodecol,
    },
  ];

  const handleChange = (key) => {
    history.replace(key);
  };

  return (
    <div className={styles.tabbar}>
      <TabBar activeKey={pathname} onChange={handleChange}>
        {tabs.map((item) => (
          <TabBar.Item
            key={item.path}
            icon={
              <img
                className={styles.tabbarIcon}
                src={
                  pathname === item.path ? item.secectedIcon : item.defaulticon
                }
                alt="tabbar"
              />
            }
            title={item.title}
          />
        ))}
      </TabBar>
    </div>
  );
};

export default TabBarCom;
