import request from "@/utils/request";

export const AuthInfo = () => {
  return request.get(`/api/v2.0/app/{app-id}/third-part/info`, {});
};

export const oauthLogin = (code, corpId, agentId) => {
  return request.get(`/api/v1.0/code/${code}`, {
    config: { disable_token: true },
    params: {
      appId: corpId,
      agentId,
    },
  });
};

/**
 * 发送登录验证码
 * sendLoginCode
 */
export const sendLoginCode = (appId, mobile) => {
  // https://fish.xuedaclass.com/api/v1.0/app/1/external/sms/app-member-messages
  // 此处支持多业务线后，appId不再传，随便传值，目前默认1
  return request.post(`/api/v1.0/app/1/external/sms/app-member-messages`, {
    disable_token: true,
    params: {
      mobile,
    },
  });
};

/**
 * 手机号登录
 * @param {object} data
 * @data
 * @param  {string}   corpId
 * @param  {string}   phone
 * @param  {string}   code
 * @returns {object:{accessToken,refreshToken}}
 */
export const phoneLogin = ({ appId, phone, code }) => {
  return request.get(`/api/v1.0/app/${appId}/phone/${phone}/code/${code}`, {
    disable_token: true,
  });
};
