import { makeAutoObservable } from "mobx"
import "./config"
import { getStorage, setStorage } from "@/utils/storage"

class TestStore {
  count = getStorage("count") || 0

  constructor() {
    makeAutoObservable(this)
  }

  get doubleCount() {
    return this.count * 2
  }

  addCount() {
    this.count++
    setStorage("count", this.count)
  }
}

export default new TestStore()
