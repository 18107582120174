import { makeAutoObservable } from "mobx";
import "./config";
import { httpDicts } from "@/utils/dict";

class Dits {
  // 类型
  type = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getDicts(type) {
    if (this[type]?.length) return;
    const res = await httpDicts(type, "student");
    this[type] = res.pickerOptions;
  }
}

export default new Dits();
