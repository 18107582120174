import { makeAutoObservable } from "mobx";
import "./config";
import { httpDicts, httpChannels, getAllBranchMember } from "@/utils/dict";

class Dits {
  // 上门人
  visitor = [];

  // 跟进状态
  clueStage = [];

  // 年级
  grade = [];

  //  性别
  sex = [];

  // 学员类型
  studentType = [];

  // 渠道
  channel = [];

  // 接触方式
  type = [];

  // 意向度
  intention = [];

  // 辅导意愿
  schoolIntention = [];

  // 电话级别
  telLevel = [];

  // 全量分公司、校区、人员
  allMember;

  constructor() {
    makeAutoObservable(this);
  }

  get channelOptions() {
    return this.channel;
  }

  async getDicts(type) {
    if (type === "allMember" && this.allMember) return;
    if (this[type]?.length) return;
    let httpMethed = httpDicts;
    if (type === "channel") {
      httpMethed = httpChannels;
    }
    if (type === "allMember") {
      httpMethed = getAllBranchMember;
    }
    const res = await httpMethed(type);
    this[type] = res.pickerOptions;
  }
}

export default new Dits();
