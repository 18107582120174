import { Toast } from "antd-mobile";
import VS from "vconsole";
import { getStorage } from "./storage";
/** 获取页面参数
 * @param {Object} name
 * @param {Object} url
 * @return {string} res
 */
export function getQuery(name, url = window.location.href) {
  const _URL = new URL(url);
  const _serach = new URLSearchParams(_URL.search);

  return _serach.get(name);
}

export function regesiterVconsole() {
  if (process.env.REACT_APP_CONSOLE === "on" || getQuery("xd_debug") === "on") {
    new VS();
  }
}

/**
 * 防抖
 * @param fn
 * @param wait
 * @returns
 */
export const debounce = (fn, wait = 500) => {
  let timer;
  return (args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(args);
    }, wait);
  };
};

// 复制文案
export const copyText = (text, show = "复制成功") => {
  const input = document.createElement("input");
  input.value = text || "";
  document.body.appendChild(input);
  input.select();
  document.execCommand("Copy");
  input.style.display = "none";
  Toast.show(show);
};

/**
 * 手机号加密
 */
export const encryptionText = (text, type = "phone") => {
  if (!text) {
    return "";
  }
  if (type === "phone") {
    return `${text.substr(0, 3)}****${text.substr(7)}`;
    // eslint-disable-next-line
  } else if (type === "wechat") {
    if (text.length < 4) {
      return "****";
    }
    const middleIndex = Math.floor((text.length - 4) / 2);
    return `${text.substr(0, middleIndex)}****${text.substr(middleIndex + 4)}`;
  }
  return "";
};

// 获取当前登录主体
export const getAppId = () => {
  return getStorage("xd_appid") || 1;
};

/**
 * 获取url中的参数
 * @returns 返回一个对象
 */
export const getUrlParams = (url) => {
  const href = url || window.location.href;
  const search = (href.includes("?") && href.split("?")[1]) || "";
  const params = {};

  if (search) {
    const searchArr = search.split("&");
    searchArr.forEach((sa) => {
      const [key, val] = sa.split("=");
      params[key] = decodeURIComponent(val);
    });
  }

  return params;
};

/**
 *  企微授权
 */

export const wxAuthority = ({ redirectUri = "", wxCorpId, agentId }) => {
  const WindowPath = window.top || window;
  const wxAuthorityUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
    wxCorpId || ""
  }&redirect_uri=${
    redirectUri || WindowPath.location.href
  }&response_type=code&scope=snsapi_privateinfo&agentid=${
    agentId || ""
  }&state=#wechat_redirect`;
  WindowPath.location.href = wxAuthorityUrl;
};

// 删除授权回掉回来的code
export const delUrlCode = () => {
  const WindowPath = window.top || window;
  const url = WindowPath.location.href;
  const loca = WindowPath.location;
  const baseUrl = `${loca.origin + loca.pathname}?`;
  const res = getUrlParams(url);
  if (res.code) {
    delete res.code;
  }

  const resUrl = JSON.stringify(res)
    .replace(/["{}]/g, "")
    .replace(/:/g, "=")
    .replace(/,/g, "&");
  const newUrl = `${baseUrl}${resUrl}`;
  WindowPath.history.replaceState(null, "", newUrl);
};

// 当前移动端
export const isMobile = () => {
  const ua = navigator.userAgent.toLowerCase();
  const isM =
    ua.match(
      /(ipod|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince|ipad)/i
    ) != null;

  return isM;
};

// 电话级别id以及电话名称
export const getTelLevelNameId = (list, id, type, flag = true) => {
  const childrenItem = list?.find((item) => item?.id === id);
  const parentItem = list?.find((item) => item?.id === childrenItem?.parentId);
  let val = null;
  if (type === "id") {
    if (id) {
      val = [parentItem?.id, id];
      return val;
    }
    val = [];
  } else if (type === "name") {
    if (id && flag) {
      val = `${parentItem?.name}/${childrenItem?.name}`;
      return val;
    }
    if (id && !flag) {
      val = `${parentItem?.name}-${childrenItem?.name}`;
      return val;
    }
    val = "";
  }

  return val;
};

// 跟进行为数据处理
export const followBehavioursDealWith = ({
  m,
  channelOptions = [],
  clueStageOptions = [],
  intentionOptions = [],
  sexOptions = [],
  gradeOptions = [],
  schoolIntentionOptions = [],
  studentTypeOptions = [],
  telLevelOptions = [],
}) => {
  const newData = { ...m };
  if (["channelId", "channelId2"].includes(m?.attribute)) {
    const channes = channelOptions
      .map((c) => {
        return [c, ...(c.children || [])];
      })
      .flat(10);
    const newValueTitle =
      (channes.find((f) => f.id === parseFloat(m.newValue)) || {}).title || "";
    const oldValueTitle =
      (channes.find((f) => f.id === parseFloat(m.oldValue)) || {}).title || "";

    let ext = "";
    if (m.opType === "replace")
      ext = `属性：${m.attributeName}由${oldValueTitle}更改为${newValueTitle}`;

    if (m.opType === "remove")
      ext = `属性：${m.attributeName}${oldValueTitle}被删除`;

    if (m.opType === "add")
      ext = `属性：${m.attributeName}被添加，值为${newValueTitle}`;
    newData.ext = ext;
  }
  if (["clueStage"].includes(m?.attribute)) {
    const newValueTitle =
      (clueStageOptions?.find((f) => String(f.id) === m?.newValue) || {})
        .name || "";
    const oldValueTitle =
      (clueStageOptions?.find((f) => String(f.id) === m?.oldValue) || {})
        .name || "";
    let ext = "";
    if (m.opType === "replace")
      ext = `属性：${m.attributeName}由${oldValueTitle}更改为${newValueTitle}`;
    // if (m.opType === 'remove') ext = `属性：${m.attributeName}${oldValueTitle}被删除`;
    if (m.opType === "add")
      ext = `属性：${m.attributeName}被添加，值为${newValueTitle}`;
    newData.ext = ext;
  }
  if (["intention"].includes(m?.attribute)) {
    const newValueTitle =
      (intentionOptions?.find((f) => String(f.id) === m?.newValue) || {})
        .name || "";
    const oldValueTitle =
      (intentionOptions?.find((f) => String(f.id) === m?.oldValue) || {})
        .name || "";
    let ext = "";
    if (m.opType === "replace")
      ext = `属性：${m.attributeName}由${oldValueTitle}更改为${newValueTitle}`;
    // if (m.opType === 'remove') ext = `属性：${m.attributeName}${oldValueTitle}被删除`;
    if (m.opType === "add")
      ext = `属性：${m.attributeName}被添加，值为${newValueTitle}`;
    newData.ext = ext;
  }
  if (["sex"].includes(m?.attribute)) {
    const newSexList = sexOptions.map((item) => ({
      ...item,
      id: item?.id ? "true" : "false",
    }));
    const newValueTitle =
      (newSexList?.find((f) => f.id === m?.newValue) || {}).name || "";
    const oldValueTitle =
      (newSexList?.find((f) => f.id !== m?.newValue) || {}).name || "";
    let ext = "";
    if (m.opType === "replace")
      ext = `属性：${m.attributeName}由${oldValueTitle}更改为${newValueTitle}`;
    // if (m.opType === 'remove') ext = `属性：${m.attributeName}${oldValueTitle}被删除`;
    if (m.opType === "add")
      ext = `属性：${m.attributeName}被添加，值为${newValueTitle}`;
    newData.ext = ext;
  }
  if (["grade"].includes(m?.attribute)) {
    const newValueTitle =
      (gradeOptions?.find((f) => String(f.id) === m?.newValue) || {}).name ||
      "";
    const oldValueTitle =
      (gradeOptions?.find((f) => String(f.id) === m?.oldValue) || {}).name ||
      "";
    let ext = "";
    if (m.opType === "replace")
      ext = `属性：${m.attributeName}由${oldValueTitle}更改为${newValueTitle}`;
    if (m.opType === "add")
      ext = `属性：${m.attributeName}被添加，值为${newValueTitle}`;
    newData.ext = ext;
  }
  if (["schoolIntention"].includes(m?.attribute)) {
    const newValueTitle =
      (schoolIntentionOptions?.find((f) => String(f.id) === m?.newValue) || {})
        .name || "";
    const oldValueTitle =
      (schoolIntentionOptions?.find((f) => String(f.id) === m?.oldValue) || {})
        .name || "";
    let ext = "";
    if (m.opType === "replace")
      ext = `属性：${m.attributeName}由${oldValueTitle}更改为${newValueTitle}`;
    if (m.opType === "add")
      ext = `属性：${m.attributeName}被添加，值为${newValueTitle}`;
    newData.ext = ext;
  }
  if (["studentType"].includes(m?.attribute)) {
    const newValueTitle =
      (studentTypeOptions?.find((f) => String(f.id) === m?.newValue) || {})
        .name || "";
    const oldValueTitle =
      (studentTypeOptions?.find((f) => String(f.id) === m?.oldValue) || {})
        .name || "";
    let ext = "";
    if (m.opType === "replace")
      ext = `属性：${m.attributeName}由${oldValueTitle}更改为${newValueTitle}`;
    if (m.opType === "add")
      ext = `属性：${m.attributeName}被添加，值为${newValueTitle}`;
    newData.ext = ext;
  }
  if (["telLevel"].includes(m?.attribute)) {
    const newValueTitle = getTelLevelNameId(
      telLevelOptions,
      Number(m?.newValue),
      "name"
    );
    const oldValueTitle = getTelLevelNameId(
      telLevelOptions,
      Number(m?.oldValue),
      "name"
    );
    let ext = "";
    if (m.opType === "replace")
      ext = `属性：${m.attributeName}由${oldValueTitle}更改为${newValueTitle}`;
    if (m.opType === "add")
      ext = `属性：${m.attributeName}被添加，值为${newValueTitle}`;
    newData.ext = ext;
  }
  return newData;
};

// 秒转换成时分秒
export const convertSecondsToHMS = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let formattedTime = "";

  if (hours > 0) {
    formattedTime += `${hours}小时`;
  }
  if (minutes > 0) {
    formattedTime += `${minutes}分钟`;
  }
  if (remainingSeconds >= 0) {
    formattedTime += `${remainingSeconds}秒`;
  }

  return formattedTime;
};

// 格式化时间秒数为为 HH:mm:ss
export const formateDuration = (value) => {
  let secondTime = parseInt(value, 10);
  let minuteTime = 0;
  let hourTime = 0;
  if (secondTime >= 60) {
    minuteTime = parseInt(secondTime / 60, 10);
    secondTime = parseInt(secondTime % 60, 10);
    if (minuteTime >= 60) {
      hourTime = parseInt(minuteTime / 60, 10);
      minuteTime = parseInt(minuteTime % 60, 10);
    }
  }
  // 补0
  hourTime = hourTime < 10 ? `0${hourTime}` : hourTime;
  minuteTime = minuteTime < 10 ? `0${minuteTime}` : minuteTime;
  secondTime = secondTime < 10 ? `0${secondTime}` : secondTime;
  const res = `${hourTime}:${minuteTime}:${secondTime}`;
  return res;
};
