const routes = [
  {
    path: "/report/juxiang",
    component: () => import("@/views/Report/JuXiang"),
    key: "/report/juxiang",
    needLogin: true,
    tabbar: false,
    title: "句象书店-运营日报",
    safeArea: false,
  },
  {
    path: "/juxiang/authority",
    component: () => import("@/views/Report/JuXiang/Authority"),
    key: "/juxiang/authority",
    needLogin: true,
    tabbar: false,
    title: "句象书店-运营日报-权限管理",
    safeArea: false,
  },
  {
    path: "/juxiang/authority/operate",
    component: () => import("@/views/Report/JuXiang/Authority/operate"),
    key: "/juxiang/authority/operate",
    needLogin: true,
    tabbar: false,
    title: "句象书店-运营日报-权限管理",
    safeArea: false,
  },
];

export default routes;
