import React from "react";
import authStore from "./auth";
import testStore from "./test";
import dictsStore from "./dicts";
import studentDictsStore from "./studentDicts";

const StoresContext = React.createContext({
  testStore,
  authStore,
  dictsStore,
  studentDictsStore,
});

export default StoresContext;
