import { makeAutoObservable } from "mobx";
import "./config";
import { showTips } from "@/utils/antd-pro";
import { oauthLogin, AuthInfo } from "@/api/login";
import jwtDecode from "jwt-decode";
import { getStorage, setStorage, setLoginInfo } from "@/utils/storage";
import { delUrlCode } from "@/utils";

class TestStore {
  count = getStorage("count") || 0;

  tokens = getStorage("token") || null;

  constructor() {
    makeAutoObservable(this);
  }

  get doubleCount() {
    return this.count * 2;
  }

  async info() {
    let res;
    try {
      res = await AuthInfo();
      if (window.location.pathname === "/report/juxiang") {
        res = {
          id: 1,
          projectId: 1,
          thirdpartCorpId: "ww67969ef74da54bfb",
          thirdpartName: "学大教育",
          ticket: "1",
          weight: 10,
          aid: 1,
          appId: 1000198,
          appName: "句象书店",
          appSecret: "QZlYeRXQ3PofhSNFQrg_5C5u8m3AjJa2mZ-ezGp9k8E",
        };
      }
    } catch (error) {
      console.log(error);
    }
    return res;
  }

  setToken(val) {
    this.tokens = val;
  }

  // eslint-disable-next-line
  async login(code) {
    const agentId = getStorage("agentId");
    const wxCorpId = getStorage("wxCorpId");
    const tip = showTips("登录中...", { icon: "loading" });
    try {
      const res = await oauthLogin(code, wxCorpId, agentId);
      if (res?.success) {
        const { accessToken, refreshToken } = res.data;
        setStorage("token", accessToken);
        setStorage("refreshToken", refreshToken);
        // 解析accessToken
        const decode = jwtDecode(accessToken);
        const { appMembers } = decode;

        if (!appMembers?.length) {
          return showTips("暂无应用权限");
        }

        setStorage("businessList", JSON.stringify(appMembers));
        const appId = getStorage("xd_appid");
        const appMembersIndex = appMembers.findIndex(
          // eslint-disable-next-line
          (item) => item.appId == appId
        );
        setLoginInfo(appMembers[appMembersIndex > -1 ? appMembersIndex : 0]);

        tip.close();
        if (accessToken) delUrlCode();
        this.tokens = accessToken;
      } else {
        showTips("登录失败");
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  addCount() {
    this.count++;
    setStorage("count", this.count);
  }
}

export default new TestStore();
