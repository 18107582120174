export const getStorage = (key) => {
  return localStorage.getItem(key);
};

export const setStorage = (key, value) => {
  return localStorage.setItem(key, value);
};

export const removeStorage = (key) => {
  return localStorage.removeItem(key);
};

export const clearStorage = () => {
  return localStorage.clear();
};

// 保存登录信息
export const setLoginInfo = (data) => {
  setStorage("xd_appid", data?.appId);
  setStorage("appName", data?.appName);
  setStorage("userId", data?.userId);
  setStorage("username", data?.name);
  setStorage("appMemberId", data?.id);
  setStorage("oa", data?.oa);

  if (data?.avatar) setStorage("avatar", data?.avatar);
  if (data?.pstnPhone) setStorage("pstnPhone", data?.pstnPhone);
  if (data?.qrCode) setStorage("qrCode", data?.qrCode);
};

// 句象书店-权限管理 白名单OA
export const getAdmin = () => {
  return [
    'youjing.xue', // 薛友静
    'xiaohui.liu6', // 刘小辉
    'huangnan_1', // 黄楠
    'zhaozhonghai', // 赵中海
    'jinwei.wang', // 王金伟
    'zhanglin_16', // 张林
  ].includes(getStorage('oa'));
};
