import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import routes from "./route";
import CacheRoute, { CacheSwitch } from "react-router-cache-route";
import Loading from "@/components/Loading";
import Layout from "@/components/Layout";

// 实现组件懒加载 可提升首屏加载速度
const SuspenseComponent = ({ Component, route, ...props }) => (
  <Layout {...route}>
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  </Layout>
);

const renderRouter = (routers) => {
  if (!Array.isArray(routers)) return null;
  return (
    <CacheSwitch>
      {routers.map((route, index) => {
        const {
          path = "",
          exact = true,
          strict = true,
          redirect = "",
          title = "学大菱境",
          component = () => {},
        } = route;

        if (redirect) {
          return (
            <Redirect
              key={path || index}
              exact={exact}
              strict={strict}
              from={path}
              to={redirect}
            />
          );
        }
        return route?.cache ? (
          <CacheRoute
            key={path || index}
            exact={exact}
            strict={exact}
            path={path}
            render={() => {
              document.title = title;
              return (
                <SuspenseComponent route={route} Component={lazy(component)} />
              );
            }}
          />
        ) : (
          <Route
            key={path || index}
            exact={exact}
            strict={exact}
            path={path}
            render={() => {
              document.title = title;
              return (
                <SuspenseComponent route={route} Component={lazy(component)} />
              );
            }}
          />
        );
      })}
      {/* <Redirect path="*" to="/"></Redirect>{" "} */}
    </CacheSwitch>
  );
};

const RouterConfig = () => {
  return <Router>{renderRouter(routes)}</Router>;
};

export default RouterConfig;
