import { Toast } from "antd-mobile";

/**
 *
 * @param {*} content
 * @param {*} rest
 * eg: const tipInstance = showTips('tishi', {
 *    icon: 'loading',
      content: '加载中…',
      duration: 5000,
      afterClose: () => {
        console.log('after')
      },
    })
    关闭当前toast： tipInstance.close()
    关闭所有toast： tipInstance.clear()
 */
export function showTips(content = "提示", rest) {
  const instance = Toast.show({
    content,
    maskClickable: false,
    ...rest,
  });

  return {
    close: () => instance.close(),
    clear: () => Toast.clear(),
  };
}
