import axios from "axios";
import { getStorage, clearStorage, setStorage } from "@/utils/storage";
import { showTips } from "@/utils/antd-pro";
import authStore from "@/store/auth";
import { wxAuthority } from "@/utils";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const UNLOGINCODE = [401, 10004];

const creatConfig = {
  baseURL,
  timeout: 60000,
  responseType: "json",
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    "client": "H5"
  },
};

const service = axios.create(creatConfig);

// 请求拦截
service.interceptors.request.use((config) => {
  const token = getStorage("token");
  // alert(config)
  if (token) {
    if (!config.disable_token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["app-member-id"] = Number(getStorage("appMemberId")) || 0;
  }
  // if (config.method === 'post') {
  //   config.data = {
  //     ...config.data
  //   }
  // } else if (config.method === 'get') {
  //   config.params = {
  //     ...config.params
  //   }
  // }
  return config;
});

// 返回拦截
service.interceptors.response.use(
  (response) => {
    if (!response?.data?.success) {
      // showTips(response?.data?.errMessage || "服务异常");
      return response.data;
    } else {
      return response.data;
    }
  },
  (error) => {
    console.log(`error${error}`); // for debug
    let errMessage = "服务异常";

    if (error.message === "Network Error") {
      errMessage = "网络连接已断开，请检查网络";
    } else if (error.message.indexOf("timeout") > -1) {
      errMessage = "请求超时，请检查网络";
    }
    const res = error.response;

    if (UNLOGINCODE.includes(+res?.status)) {
      errMessage = "授权已过期，请重新登录";
      // showTips(errMessage);
      // setTimeout(() => {
      clearStorage();
      authStore
        .info()
        .then((result) => {
          if (result?.thirdpartCorpId && result?.appId) {
            setStorage("agentId", result?.appId);
            setStorage("wxCorpId", result?.thirdpartCorpId);
            wxAuthority({
              wxCorpId: result?.thirdpartCorpId,
              agentId: result?.appId,
              // wxCorpId: "ww67969ef74da54bfb",
              // agentId: "1000109",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // }, 1500);
      return Promise.reject(error.response);
    }
    showTips(errMessage);
    return Promise.reject(error.response);
  }
);

export default service;
