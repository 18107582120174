import React from "react";
import "./index.scss";
import { DotLoading } from "antd-mobile";


const Loading = (props) => {
  const { content = "" } = props;

  return (
    <div className='loading'>
      {/* <div className='loading-icon'></div> */}
      <span style={{ fontSize: 18, marginTop: 8 }}>
        <DotLoading />
      </span>
      <span style={{ marginTop: 8 }}>{content}</span>
    </div>
  );
};

export default Loading;
